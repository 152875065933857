import { Box, Chip, Tooltip } from "@mui/material"
import { memo } from "react"
import ChatIcon from '@mui/icons-material/Chat';
import { stringToTitleCase } from "../../../../utils/stringUtils/titleCase";
import { StethoscopeIcon } from "../../../../assets/icons/MaterialDesignIcons";


const MDApprovedStatusChip = ({mdApproved, qaObj=null, mdReviewObj={}}) => {
    const {review_state_string} = qaObj || {}


    const status = mdApproved ? 'Completed' : 'Submitted'
    const qaStatus = stringToTitleCase(review_state_string)

    return(
        <Box className='flex-row' gap={0.5} height={'100%'} alignItems='center'>
            <Chip label={status} size='small' color={mdApproved? 'success' : 'warning'} />
            {
                (qaStatus === 'Rejected' || qaStatus === 'Approved') &&
                <Tooltip title={'Case has QA feedback - ' + qaStatus + ' ' + qaObj?.approval_date || '' }>
                    <ChatIcon color='action'/>
                </Tooltip>
            }
            {
                mdReviewObj?.feedback &&
                <Tooltip title='Case has MD feedback'>
                    <Box className='flex-row-center'>
                        <StethoscopeIcon color='action'/>
                    </Box>
                </Tooltip>
            }
        </Box>
    ) 
}


export default memo(MDApprovedStatusChip)
