import { useTheme } from "@emotion/react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import HeaderFrameLayout from "../../../../globalComponents/Headers/HeaderFrames/FrameLayout/FrameLayout";
import { selectCurrentUser } from "../../../../auth/authSlice";
import { useSelector } from 'react-redux'






export default function ClinicianFrame({
    billableEvent,
    encounter,
    pendingQA,
    compensationObjs
}) {

    const theme = useTheme()
    let { encounterType } = useParams()
    const { id: userId, groups, name } = useSelector(selectCurrentUser)

    //fields that differ between Pathology and InfecDis
    //this should be handled in the serializer not the front end!!
    const dateSubmitted = encounterType.includes('Pathology') ? encounter?.date_submitted : encounter?.submitted_at;
    const duration = encounterType.includes('Pathology') ? encounter?.time_spent_reviewing_entered : encounter?.general?.time_spent_reviewing_entered_in_minutes
    const treatingProvider = encounterType.includes('Pathology') ? encounter?.treating_provider : encounter?.general?.treating_provider

    const billingPts = pendingQA ? 'QA pending' : billableEvent?.point_value
    const compensationPts = pendingQA ? 'QA pending' : compensationObjs?.filter((obj) => obj?.user === userId)?.reduce((sum, obj) => sum + Number(obj?.points), 0)?.toString()

    const isReviewClinician = compensationObjs?.some((obj) => obj?.user === userId) || (encounter?.review_metadata?.first_pass_user === userId || encounter?.review_metadata?.second_pass_user === userId) || encounter?.clinician_name.toLowerCase() === name.toLowerCase()

    const generatePtsRow = () => {
        if (groups?.['Internal Staff'] && (billableEvent || pendingQA)) {
            return {
                label: 'Billing pts.', value: billingPts,
                valueSx: pendingQA ? { color: theme.palette.warning.main } : null
            }
        }

        else if (isReviewClinician && (compensationObjs || pendingQA)) {
            return {
                label: 'Personal compensation pts.', value: compensationPts,
                valueSx: pendingQA ? { color: theme.palette.warning.main } : null
            }
        }
        else {
            return
        }
    }

    const firstRow = [
        { label: 'Last submitted', value: dayjs(dateSubmitted).format('LL') || '-' },
        { label: 'Duration', value: duration ? duration + ' min' : '-' },
        generatePtsRow(),
        { label: 'Site', value: encounter?.patient?.site?.site_name },
    ]

    const secondRow = [
        { label: 'Treating Provider', value: treatingProvider },
        { label: 'CPT Codes', value: billableEvent?.cpt_codes?.map(event => event.cpt_code.code).join(', ') },
    ]

    return (
        <HeaderFrameLayout
            avatarColor={theme.avatar.primary}
            name={encounter?.clinician_name}
            namesArray={encounter?.review_clinicians_names}
            firstRow={firstRow}
            secondRow={secondRow} />
    )
}