import { DataGridPro } from "@mui/x-data-grid-pro"
import { useGetCaseObjectsQuery } from "../../../../../../rosterAdminAPISlice"
import LinkedPatientCell from "../../../../../../../globalComponents/DataGrid/subComponents/customCells/LinkedPatientCell"
import { AssignmentStatusChipCell } from "../../../../../../../globalComponents/DataGrid/subComponents/customCells/AssignmentStatusChipCell"
import { Box, Divider, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { selectDailyCasesFilters, setDailyCasesFilters } from "../../../../../../../admin/adminSlice"
import { useCallback, useState } from "react"
import usePaginatedRowCount from "../../../../../../../globalComponents/DataGrid/hooks/commonUseEffects/usePaginatedRowCount"
import { Filters } from "./Filters"
import KeepMountedTabPanel from "../../../../../../../globalComponents/Tabs/TabPanels/KeepMountedTabPanel"
import CaseMgmtToolbar from "./CaseMgmtToolbar/CaseMgmtToolbar"
import { hideSelectAllCheckBoxProps } from "../../../../../../../globalComponents/DataGrid/gridStyleProps/gridStyleProps"
import { Link } from "react-router-dom"
import { stringToTitleCase } from "../../../../../../../../utils/stringUtils/titleCase"


const columns = [
    {
        field:'id',
        headerName: 'Id',
        width:75,
    },
    {
        field: 'case_type__name',
        headerName: 'Type',
        flex:1,
        valueGetter: (value, row) => row?.case_type ? row.case_type : '-',
    },
    {
        field: 'patient__first_name',
        headerName: 'Patient',
        flex:1,
        valueGetter: (value, row) => row?.patient ? row.patient : '-',
        renderCell: ({row}) => <LinkedPatientCell patientId={row?.patient_id} patientName={row?.patient} />
    },
    {
        field:'patient__site__site_name',
        headerName: 'Site',
        flex:1,
        valueGetter: (value, row) => row?.site ? row.site : '-'
    },
    {
        field:'resolution_status',
        headerName: 'Status',
        minWidth: 180,
        renderCell: ({row}) =>  <AssignmentStatusChipCell row={{case_resolution_status: row?.resolution_status}} />
    },   
    {
        field:'user__first_name',
        headerName: 'Clinician',
        flex:1,
        valueGetter: (value, row) => row?.user ? row.user?.name : '-',
        renderCell: ({row}) => (
            <Box className='flex-row' 
                sx={{ 
                    height:'100%', 
                    width:'100%', 
                    }}>
                <Link className='flex-row' style={{ textDecoration: 'none', height:'100%', width:'100%', alignItems:'center' }} to={'/admin/clinician/' + row?.user?.id} title={row?.user?.name}>
                    <Typography variant='body2' noWrap color={'primary'}>{stringToTitleCase(row?.user?.name)}</Typography>
                </Link>
            </Box>
        )
    },
]

export function RMCasesGrid({
    gridProps,
    pageSizeOptions,
    pageSize,
    setPageSize,
    visible
}){

    const dispatch = useDispatch()

    const queryParams = useSelector(selectDailyCasesFilters)
    const paginatedParams = {...queryParams, pagination: {...queryParams.pagination, pageSize}}
    
    const setQueryParams = useCallback((state) => {dispatch(setDailyCasesFilters(state))}, [dispatch])
    const { data, isLoading, isFetching } = useGetCaseObjectsQuery(paginatedParams)
    const rowCount = usePaginatedRowCount(data?.count)
    const [selected, setSelected] = useState([])
    const [selectedRowData, setSelectedRowData] = useState([])
    const isSelected = selected?.length > 0

    const handlePaginationChange = ({pageSize, page}) => {
        setQueryParams({pagination: {
            ...paginatedParams.pagination,
            page,
        }}) 
        setPageSize(pageSize)
    }

    const resetPagination = () => setQueryParams({pagination: { page:0, pageSize:10 }})

    const handleFilterChange = (filterName) => (value) => {
        setQueryParams({[filterName]: value })
        resetPagination()
    }

    const handleSortChange = (sortModel) => {
        const {field, sort} = sortModel[0] || {}
        
        let order = field

        if(sort === 'desc'){
            order = '-' + order
        }

        resetPagination()
        setQueryParams({ordering:order})
    }

    const handleRowSelection = (selectedRowIds) => {
        setSelected(selectedRowIds)

        const selectedRowsData = selectedRowIds.map(id => 
            data?.results.find(row => row.id === id)
        )
        setSelectedRowData(selectedRowsData)
    }

    const handleIsRowSelectable = ({ row }) => {
        const status = row?.resolution_status;
            return ['pending', 'in_progress', 'assigned', 'failed', 'pending_results'].includes(status);
    };

    return( 
        <KeepMountedTabPanel className={visible ? 'visible' : ''}>
            <Filters handleFilterChange={handleFilterChange} queryParams={queryParams}/>
            <Divider />
            <DataGridPro 
                rows={data?.results || []}
                columns={columns}   
                rowCount={rowCount}
                pagination
                sortingMode="server"
                paginationMode="server"
                paginationModel={paginatedParams?.pagination} //needs pagsize now
                onPaginationModelChange={handlePaginationChange}
                pageSizeOptions={pageSizeOptions}
                loading={isLoading || isFetching}
                onSortModelChange={handleSortChange}    
                checkboxSelection
                disableRowSelectionOnClick
                isRowSelectable={handleIsRowSelectable}
                rowSelectionModel={selected}
                onRowSelectionModelChange={handleRowSelection}
                {...gridProps}
                sx={{
                    ...gridProps.sx,
                    ...hideSelectAllCheckBoxProps
                }}
                slots={{
                    ...gridProps?.slots,
                    toolbar: CaseMgmtToolbar
                }}
                slotProps={{
                    toolbar:{isSelected, selected, setSelected, selectedRowData}
                }}
                columnHeaderHeight={isSelected? 0 : 52}
            />
        </KeepMountedTabPanel>
    )
}