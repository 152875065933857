import { useParams } from "react-router-dom";
import PatientHeader from "../../patients/components/PatientHeader/PatientHeader";
import { Box, Skeleton } from "@mui/material";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useGetMDReviewDetailsQuery } from "../MDApiSlice";
import BackButton from "../../globalComponents/SubHeader/Backbutton";
import { SmallHeaderBox, TypographySubtitle } from "../../globalComponents/SubHeader/components";
import { stringToTitleCase } from "../../../utils/stringUtils/titleCase";
import MDFeedbackForm from "../components/MDReview/MDFeedbackForm";
import PathologyDetailsContainer from "../../pathology/components/PathologyReview/PathologyDetailsContainer";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setStaticMeds } from "../../pathology/pathologySlice";
import { AccordionLoader, CaseHeaderLoader, SubHeaderLoader } from "../../globalComponents/Loaders";



export default function MDReview() {

    let { reviewId } = useParams()
    const dispatch = useDispatch()
    const { data, isLoading, isFetching } = useGetMDReviewDetailsQuery(reviewId, {refetchOnMountOrArgChange:true})
    const isInfecDisCase = false

    const { encounter, paginated_lab_history, paginated_encounter_history, } = data || {}

    useEffect(() => {

        dispatch(setStaticMeds(
            encounter?.patient?.medications
        ))

    }, [encounter])

    if(isLoading || isFetching) {
        return(
            <>
            <SubHeaderLoader />
            <CaseHeaderLoader />
            <Box className='flex-col' p={3} gap={1}>
                <Skeleton  width={150} sx={{mb:3}} variant={'rounded'}/>
                <AccordionLoader />
                <AccordionLoader />
                <AccordionLoader />
            </Box>
            </>
        )
    }   


    return(
        <>
            <PatientHeader 
                patient={encounter?.patient} 
                paginated_encounter_history={paginated_encounter_history}
                paginated_lab_history={paginated_lab_history}
                readOnly>
                    <SmallHeaderBox justifyContent="flex-row-between">
                        <BackButton/>
                        <TypographySubtitle>{stringToTitleCase(encounter?.clinician_name) + "'s case submission" }</TypographySubtitle>
                    </SmallHeaderBox>
            </PatientHeader>
            <Box className='flex-row' height={'calc(100vh - 64px)'} >

                <Box sx={{ flex: 1, pl: 3, pt: 3, position: 'sticky', top: 0 }}>
                    <MDFeedbackForm queryLoading={isLoading || isFetching} reviewId={reviewId}/>
                </Box>
            
                <OverlayScrollbarsComponent style={{flex:2}}>
                    <Box className='flex-col' sx={{ m:3, gap:3}}>
                        {
                            isInfecDisCase?
                                null
                                :
                                <PathologyDetailsContainer encounter={encounter} isLoading={isLoading || isFetching}/>
                        }
                    </Box>
                </OverlayScrollbarsComponent>
            </Box>
        </>
    )
}