import { Box } from "@mui/material";
import { useGetMDReviewListQuery } from "../../MDApiSlice";
import dayjs from "dayjs";
import LinkedPatientCell from "../../../globalComponents/DataGrid/subComponents/customCells/LinkedPatientCell";
import { useDispatch, useSelector } from "react-redux";
import { selectMDReviewListFilters, setMDReviewListFilters } from "../../../admin/adminSlice";
import usePaginatedRowCount from "../../../globalComponents/DataGrid/hooks/commonUseEffects/usePaginatedRowCount";
import { useNavigate } from "react-router-dom";
import { DataGridPro } from "@mui/x-data-grid-pro";
import DefaultNoRowsOverlay from "../../../globalComponents/DataGrid/subComponents/noRowsOverlays/DefaultNoRowsOverlay";
import { MemoCell } from "../../../globalComponents/DataGrid/performance/MemoGridItems";
import { useCallback } from "react";
import { linkedRowProps } from "../../../globalComponents/DataGrid/gridStyleProps/gridStyleProps";



const columns = [
    {
        field:'case_type',
        headerName: 'Type',
        flex:1,
        maxWidth:250,
    },
    {
        field: 'patient',
        headerName: 'Patient',
        width:200,
        renderCell: (params) => (
            <LinkedPatientCell
                patientName={params?.value}
                patientId={params?.row?.patient_id}
            />
        )
    },
    {
        field: 'clinician',
        headerName: 'Clinician',
        flex:1,
        valueGetter: (value, row) => row?.user?.name
    },
    {
        field:'site',
        headerName: 'Site',
        flex:1,
        maxWidth: 300,
    },
    {
        field: 'date_submitted',
        headerName: 'Date submitted',
        width:170,
        valueFormatter: (value, row) => {
            if(!value) return '-'
            if(value === 'n/a') return value
            return dayjs(value).format('LL')
        }
    }
]

const sx = {
    border:'none',
    ...linkedRowProps
}

export default function MDReviewPanel(){

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useSelector(selectMDReviewListFilters)
    const { data, isLoading, isFetching} = useGetMDReviewListQuery(params)
    const rowCountState = usePaginatedRowCount(data?.count)

    const setParams = (state) => dispatch(setMDReviewListFilters(state))

    const handlePaginationChange = (pageModel) => setParams({pagination:pageModel}) 

    // const handleFilterChange = (filterName) => (value) => {
    //     setParams({[filterName]: value })
    //     resetPaginaton()
    // };

    const handleCellClick = useCallback(({id, field}) => {

        if (field.includes('__') || field === 'patient') return

        navigate('md/review/' + id)

    }, [])

    return( 
        <Box p={3}>
            <Box sx={(theme) => ({...theme.standardBox})}>
                <DataGridPro
                    columns={columns}
                    rows={data?.results || []}
                    paginationMode="server"
                    sortingMode="server"
                    pagination
                    autoHeight
                    disableColumnMenu
                    disableColumnSorting
                    rowCount={rowCountState}
                    loading={isLoading || isFetching}
                    pageSizeOptions={[5, 10, 15, 20, 50]}
                    paginationModel={params.pagination}
                    onPaginationModelChange={handlePaginationChange}
                    onCellClick={handleCellClick}
                    slots={{
                        noRowsOverlay: DefaultNoRowsOverlay,
                        cell:MemoCell
                    }}
                    slotProps={{
                        noRowsOverlay: { message: 'No pending cases to review' },
                    }}
                    sx={sx}
                    // checkboxSelection
                />
            </Box>
        </Box>
    )
}