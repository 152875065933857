import { Route } from "react-router-dom";
import RequireAuth from "../auth/RequireAuth";
import Admin from "./pages/Admin";
import QAReview from "../qualityAssurance/pages/QAReview";
import MDReview from "../MDReview/pages/MDReview";


export const adminRoutes = [
    <Route path='/admin' element={<RequireAuth />} key='adminRoutes'>
        <Route path="/admin" element={<Admin/>}/>
        <Route path='/admin/quality-assurance/review/:qualityId' element={<QAReview />} />
        <Route path='/admin/md/review/:reviewId' element={<MDReview />}/>
    </Route>
]
