import { useCallback, useState } from "react"
import {AssignmentIcon} from '../../../../../assets/icons/MaterialDesignIcons'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import ArchiveDialog from "../../../../rosterManagement/admin/components/ArchiveDialog/ArchiveDialog";
import OutlinedToolbarChip from "../../../../globalComponents/Chips/OutlinedToolbarChip/OutlinedToolbarChip";
import StickyHeaderToolbar from "../../../../globalComponents/DataGrid/subComponents/toolBars/StickyHeaderToolbar";
import SinglePatientAssignmentModal from "../../../../rosterManagement/admin/components/SinglePatientAssignmentModal/SinglePatientAssignmentModal";


export function PatientListToolbar({selected, setSelected, groups, selectedRowData}){

    const [modalOpen, setModal] = useState('')
    
    const closeModal = useCallback((event, reason) => {
        if(reason === 'backdropClick') {
            return;
        }
        setModal('')
    }, [])
    const openModal = (modalType) => {
        setModal(modalType)
    }
    
    const resetState  = useCallback(() => {
            setSelected([])
        }, []
    )

    return(
        <StickyHeaderToolbar sx={{height:56}}>
            {
                (groups?.Assignment || groups?.['Internal Staff'] ) &&
                <OutlinedToolbarChip
                    onClick={() => openModal('assign')}
                    startIcon={<AssignmentIcon sx={{ height:18, width:18 }}/>}>
                    Assign
                </OutlinedToolbarChip>
            }
            {
                (groups?.['Internal Staff'] || groups?.['Developer']) &&
                <OutlinedToolbarChip
                    onClick={() => openModal('archive')}
                    startIcon={<ArchiveOutlinedIcon sx={{ height:18, width:18 }}/>}>
                    Archive
                </OutlinedToolbarChip>
            }
            <SinglePatientAssignmentModal
                open={modalOpen === 'assign'}
                handleClose={closeModal}
                patientId={selected} //passing the selected row to trigger fecthing to give the impression of a 'Fast UI'
                resetParentCallback={resetState}
            />
            <ArchiveDialog
                open={modalOpen === 'archive'}
                handleClose={closeModal}
                patientId={selected[0]}
                patientObj={selectedRowData}
                resetParentCallback={resetState}
            />

        </StickyHeaderToolbar>
    )





}