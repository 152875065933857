import Recommendations from "./Recommendations/Recommendations";
import DataInput from "./DataInput/DataInput";
import Samples from "./Samples/Samples";






export default function InfectiousDiseaseReviewContainer({data, isUnder18}){

    const { encounter, med_recs_options, sample_menu_options} = data || {}
    

    return(
        <>
        <DataInput 
            allergies={encounter?.patient?.allergies}  
            client={encounter?.general}  
            diagnoses={encounter?.patient?.icd10_references}
            general={encounter?.general}/>
        <Samples 
            initSamples={encounter?.samples}
            sample_menu_options={sample_menu_options}/>
            
        <Recommendations
            isUnder18={isUnder18}
            medInstructions={encounter?.medications} 
            general={encounter?.general} 
            drugInteractions={encounter?.d2d_interactions} 
            med_recs_options={med_recs_options}/>
        </>
    )
}





