import { Button, Box } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useRef, useState } from "react";
import axiosAuth from "../../../../utils/apiUtils/axiosAuth";
import { enqueueSnackbar } from "notistack";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';



function downloadDoc(fileURL, filename){
    const downloadLink = document.createElement('a');
    downloadLink.href = fileURL
    downloadLink.download = filename[1];
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

export default function DocDownloadBtn({ encounterId, isInfecDisCase=false}){
    const [open, setOpen] = useState(false)
    const anchorRef = useRef(null)

    const handleToggle = () => setOpen((prevOpen) => !prevOpen)
    const handleDocumentClick  = (e, docType) => {

        const dataType = isInfecDisCase ? 'Encounter Document' : 'Pathology Encounter'
        const caseType = isInfecDisCase ? 'Infectious Disease' : 'Pathology'

        axiosAuth({
            url:`roster/download/doc/${dataType}/${encounterId}/${docType}/${caseType}`,
            method:'GET',
            responseType: "blob" // needed for handling file responses 
        })
            .then(res=> {
                const contentType = res.headers['content-type']; 
                const contentDisposition = res.headers['content-disposition']; 
                const filename = contentDisposition.match(/filename="([^"]+)"/); 
                const blob = new Blob([res.data], { type: contentType }); 
                const fileURL = URL.createObjectURL(blob);
                
                downloadDoc(fileURL,filename)
                handleClose()

            })
            .catch(err => {
                //using manual error handling since this is an axiosAuth request not through RTKQuery 
                if(err?.response?.status === 404) enqueueSnackbar('File not found', {variant:'error'})
                if(err?.response?.status === 400 ) enqueueSnackbar('The document is generating. If it takes more than a few minutes, please contact support.', {variant:'error'})              
            })   
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
          event.preventDefault();
          setOpen(false);
        } else if (event.key === 'Escape') {
          setOpen(false);
        }
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
    
        setOpen(false);
    };

    return(
        <Box>
            <Button 
                ref={anchorRef}
                onClick={handleToggle}
                variant="outlined" 
                endIcon={<KeyboardArrowDownIcon/>} 
                sx={{
                    borderRadius:'18px', 
                    height:32,
                    zIndex:1200
                }}>
                    Download
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                transition
                sx={{zIndex:1101}}
                >
                {({ TransitionProps, placement }) => (
                    <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                        placement === 'bottom-start' ? 'left top' : 'left bottom',
                    }}
                    >
                        <Paper >
                        <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                                autoFocusItem={open}
                                onKeyDown={handleListKeyDown}
                                sx={{borderRadius:1, zIndex:1200}}>
                                <MenuItem onClick={(e) => handleDocumentClick(e,'PDF')}sx={{ width:120, minWidth:120 }}>PDF</MenuItem>
                                {!isInfecDisCase && <MenuItem onClick={(e) => handleDocumentClick(e,'DOCX')} sx={{ width:120, minWidth:120 }}>DOCX</MenuItem>}
                                {/* per Ford, we should only be serving up PDFs so I removed DOCX from path and ID */}
                        </MenuList>
                        </ClickAwayListener>
                        </Paper>
                    </Grow>
                    )}
                </Popper>
        </Box>
    )
}