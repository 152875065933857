import { useTheme } from "@emotion/react"
import { Box, Chip, Tooltip } from "@mui/material"
import { memo } from "react"
import { stringToSentenceCase } from "../../../../utils/stringUtils/sentenceCase"
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ChatIcon from '@mui/icons-material/Chat';
import { StethoscopeIcon } from "../../../../assets/icons/MaterialDesignIcons";

const CaseStatusChip = ({caseStatus, qaStatus=null, mdReview={}}) =>{  

    const theme = useTheme()

    const colorToStatus = {
        //grey
        Pending: theme.palette.mode === 'dark' ? theme.palette.grey[600] :  theme.palette.action.active,
        Assigned: theme.palette.mode === 'dark' ? theme.palette.grey[600] :  theme.palette.action.active,
        //green
        'Pending results': theme.palette.success.main,
        Completed: theme.palette.success.main,
        Approved: theme.palette.success.main,
        'No action needed': theme.palette.success.main,
        Resolved: theme.palette.success.main,

        //red
        'In progress': theme.palette.primary.main,
        Delayed: theme.palette.warning.main,
        Rejected: theme.palette.error.main,
        'Rejected (md)': theme.palette.error.main,
        'Did not finish': theme.palette.error.main,

        
    }


    
    return (
        <Box className='flex-row' gap={1} height={'100%'} alignItems='center'>
            <Chip 
                title={stringToSentenceCase(caseStatus)} 
                label={stringToSentenceCase(caseStatus)} 
                size='small' 
                color='warning' 
                sx={{color:'#fff', background:colorToStatus[stringToSentenceCase(caseStatus)]}}/>
            {
                (qaStatus === 'Pending' || qaStatus === 'In Progress') ?
                <Tooltip title='Case is under QA review'>
                    <ReportProblemIcon color="warning"/>
                </Tooltip>
                :
                (qaStatus === 'Rejected' || qaStatus === 'Approved') ? 
                <Tooltip title={'Case has QA feedback - ' + qaStatus?.toLowerCase()}>
                    <ChatIcon color='action'/>
                </Tooltip>
                :
                null
            }
            {
                mdReview?.feedback &&
                <Tooltip title='Case has MD feedback'>
                    <Box className='flex-row-center'>
                        <StethoscopeIcon color='action'/>
                    </Box>
                </Tooltip>
            }

        </Box>  

    )

}

export default memo(CaseStatusChip)
