import dayjs from "dayjs";
import { apiSlice } from "../../app/api/apiSlice";


export const rosterApiSlice = apiSlice
.enhanceEndpoints({addTagTypes:['MonthsSchedules', 'Caseload', 'Encounter History', 'Case Details']})
.injectEndpoints({
    endpoints: builder => ({

        getMonthSchedules: builder.query({
            query:(date) => 'roster/daily-schedules/' + date,
            providesTags:['MonthsSchedules'],
            keepUnusedDataFor: 1
        }),
        upsertDailySchedules: builder.mutation({
            query: (payload) =>({
                url: 'roster/daily-schedules/bulk-upsert',
                method:'POST',
                body:payload
            }),
            invalidatesTags:['MonthsSchedules', 'Caseload', 'Dashboard']
        }),
        setCaseloadPrefs: builder.mutation({
            query:(payload) =>({
                url: 'roster/caseload/preferences',
                method:'PUT',
                body:payload
            })
        }),
        getCaseload: builder.query({
            query: (today) => 'roster/caseload/' + today,
            providesTags:['Caseload'],
        }),
        requestCases: builder.mutation({
            query: ({payload, today}) =>({
                url: 'roster/caseload/case-req/' + today,
                method: 'PUT',
                body: payload
            }),
            invalidatesTags:['Dashboard']
        }),
        delayCase:builder.mutation({
            query:({payload, today}) =>({
                url:'roster/case/delay-or-remove/delay/' + today,
                method:'PUT',
                body:payload
            }),
            onQueryStarted({ patch, today}, { dispatch, queryFulfilled }) {
    
                const patchResult = dispatch(
                    apiSlice.util.updateQueryData('getCaseload', today, (draft) => {
                        const index = draft.assignments.findIndex(assignment => assignment.case === patch.case);
                        if (index !== -1) {
                            draft.assignments[index] = patch;
                        }
                    })
                )
                queryFulfilled.catch(patchResult.undo)
            },
            invalidatesTags:['Dashboard']
        }),
        removeCase:builder.mutation({
            query:({payload, today}) =>({
                url:'roster/case/delay-or-remove/remove/' + today,
                method:'PUT',
                body:payload
            }),
            invalidatesTags:['Caseload', 'Dashboard']
        }),
        getCase: builder.query({
            //will need to pass case type so that this endpoint works for the case details page ?
            query:(caseId) => 'roster/case/' + caseId,
            keepUnusedDataFor:60,
            
        }),
        finalizeCase: builder.mutation({
            query:(params) =>({
                url: 'roster/case/finalize/' + params.caseId,
                method:'POST',
                body:params.payload
            }),
            invalidatesTags:['Dashboard', 'Dashboard Charts', 'Caseload', 'Encounter History', 'Compensation Stats']
        }),
        getEncounterHistory: builder.query({
            query: (params) => {
                const {type, ordering, search, page, md_approved, pagination, sites, start_date, end_date} = params || {}
                let url = ''

                if(type === 'Pathology Encounter'){
                    url = `pathology/user-history/?search=${search}&page=${page}&md_approved=${md_approved}&size=${pagination.pageSize}`
                    if (ordering){
                        url += `&ordering=${ordering}`
                    }
    
                    if(sites?.length > 0){
                        let siteNameArr = []
                        for (let i=0; i<sites.length; i++){
                            siteNameArr.push(sites[i].site_name)
                        }
                        url += '&site_name=' + siteNameArr.join(',')
                    }

                    if(start_date) {
                        url += '&start_date=' + start_date
                        url += '&end_date=' + end_date
                    }
                }
                else if(type === 'Infectious Disease Encounter'){
                    url =  `infectious-disease/user-history/?search=${search}&page=${page}&md_approved=${md_approved}&size=${pagination.pageSize}`
                    if (ordering){
                        url += `&ordering=${ordering}`
                    }
    
                    if(sites?.length > 0){
                        let siteNameArr = []
                        for (let i=0; i<sites.length; i++){
                            siteNameArr.push(sites[i].site_name)
                        }
                        url += '&site_name=' + siteNameArr.join(',')
                    }

                    if(start_date) {
                        url += '&start_date=' + start_date
                        url += '&end_date=' + end_date
                    }
                }

                return url
            },
            providesTags:['Encounter History']
        }),
        getEncounterDetials: builder.query({
            query: ({encounterType, encounterId}) => `roster/encounter-details/${encounterType}/${encounterId}`,
            providesTags:['Case Details']
        }),
        getPDF: builder.query({
            query:(params) => 'roster/download/pdf/' + params.type + '/' + params.id
        }),
        logSessionEntryEndTime: builder.mutation({
            query:(sessionEntryId) => ({
                url: `roster/sessions/record-exit/${sessionEntryId}`,
                method: 'PUT',
            }),
        }),
        uploadLabDocs: builder.mutation({
            query:(payload) => ({
                url: 'roster/upload-lab-doc',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags:['Patient Profile']
        }),
        getFaxRecords: builder.query({
            query:(patientId) => `fax/${patientId}`
        }),
        sendFaxRecord: builder.mutation({
            query:(payload) => ({
                url: 'fax/',
                method: 'POST',
                body: payload
            })
        }),
        getRosterUsers: builder.query({
            query: (params) => {

                let {allowedSite='' } = params || {}

                return 'roster/roster-users/?allowed_site_id=' + allowedSite 
            }
        }),
        getCaseTypes: builder.query({
            query: (params) => `roster/case-types/?site=${params?.site || ''}`
        }),
        assignToBatchOrClinician: builder.mutation({
            query:({target, body}) => ({
                url:'roster/case/asgmt/' + target,
                method:'POST',
                body,
                prepareHeaders: (headers) => {
                    headers.set("Content-Type", "multipart/form-data");
                    return headers;
                },
            }),
            
            onQueryStarted({ body,target}, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    apiSlice.util.updateQueryData('getPatientProfile', {patientId:[body?.patient_id], extensive:false}, (draft) => {
                        if(target === 'batch'){
                           draft.patient.existing_batches.unshift({batch_date: dayjs(body?.batch_date).format('YYYY-MM-DD')})
                       }else{
                            draft.patient.active_case = {   case_type: body?.case_type?.name ,
                                                            clinician:body?.clinician?.name }
                       }
                    })
                )
                queryFulfilled.catch(patchResult.undo)
            },
            invalidatesTags:['Patient List', 'Caseload', 'Patient Profile', 'Roster Admin | All']
        })
    })
})


export const { 
    useGetMonthSchedulesQuery, useGetCaseloadQuery, useGetEncounterHistoryQuery, 
    useGetCaseQuery, useUpsertDailySchedulesMutation, useSetCaseloadPrefsMutation, 
    useFinalizeCaseMutation, useRequestCasesMutation, useLazyGetPDFQuery, useGetEncounterDetialsQuery,
    useDelayCaseMutation, useRemoveCaseMutation, useLogSessionEntryEndTimeMutation, useUploadLabDocsMutation,
    useGetRosterUsersQuery, useGetCaseTypesQuery, useAssignToBatchOrClinicianMutation, useGetFaxRecordsQuery, useSendFaxRecordMutation
} = rosterApiSlice  
