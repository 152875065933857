import Box from '@mui/material/Box'
import { useParams } from 'react-router-dom'
import { useGetPatientProfileQuery } from '../patientAPISlice'
import PatientVerticalCard from '../components/PatientProfile/PatientVerticalCard/PatientVerticalCard'
import PatientSummary from '../components/PatientProfile/PatientSummary/PatientSummary'
import BasicPatientHeader from '../components/Subheaders/BasicPatientHeader/BasicPatientHeader'
import BasicPatientHeaderLoader from '../components/Subheaders/BasicPatientHeader/BasicPatientHeaderLoader'
import PatientVerticalCardLoader from '../components/PatientProfile/PatientVerticalCard/PatientVerticalCardLoader'
import PatientSummaryLoader from '../components/PatientProfile/PatientSummary/PatientSummaryLoader'






export default function PatientProfile() {

    let { patientId } = useParams()
    const { data, isLoading, isFetching } = useGetPatientProfileQuery({ patientId })

    const { patient, history, assignments, ipc_history } = data || {}
    const { first_name, middle_name, last_name } = patient || {}

    if (isLoading || isFetching) {
        return (
            <>
                <BasicPatientHeaderLoader />
                <Box p={3} className='flex-row' gap={3} height={700}>
                    <PatientVerticalCardLoader />
                    <PatientSummaryLoader />
                </Box>
            </>
        )
    }
    return (
        <>

            <BasicPatientHeader patientName={`${first_name} ${middle_name ?? ''} ${last_name}`} patientId={patient?.id}/>
            <Box className='flex-row' justifyContent={'center'} gap={3} p={3} flexWrap={'wrap'}>

                <PatientVerticalCard patient={patient} />

                <PatientSummary
                    history={history}
                    assignments={assignments}
                    ipcHistory={ipc_history} />
            </Box>
        </>
    )


}
