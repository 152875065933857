import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import { useTheme } from '@emotion/react';
import { Box, IconButton, Typography } from '@mui/material';
import { getEntryIcon } from './EntryIcon/getEntryIcon';
import { QABody } from './EntryBody/QABody';
import { EncounterBody } from './EntryBody/EncounterBody';
import { LabBody } from './EntryBody/LabBody';
import { useNavigate } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useState } from 'react';
import DeleteDocDialog from './DeleteDocDialog/DeleteDocDialog';
import DeleteEncounterDialog from './DeleteEncounterDialog/DeleteEncounterDialog';





export default function HistoryEntry({ entry, isDeveloper }) {
    const theme = useTheme();
    const navigate = useNavigate()
    const [ docToArchive , setArchiveDoc ] = useState(false)
    const [ encounterToDelete, setEncounterToDelete ] = useState(false)
    const [ open, setOpen ] = useState('')
    const { type, data } = entry;
    const timeLineDot = getEntryIcon(type, theme);
    const isEncounter = type === 'Infectious Disease Encounter' || type === 'Pathology Encounter';

    const handleClose = () => setOpen('')

    const handleNav = () => {

        if(isEncounter){
            navigate(`/caseload/encounter-details/${type}/${data?.id}`)
        }else{
            const newTab = window.open();
            if (newTab) {newTab.location.href = `${process.env.REACT_APP_API_URL}roster/download/doc/${type}/${data?.id}/pdf`}
        }
    }

    return (
        <TimelineItem 
            onClick={handleNav}
            sx={{
                ':hover':{
                    background:theme.palette.action.hover,
                    cursor:'pointer'
                },
                pl:2,
                pr:2
            }}>
            <TimelineSeparator>
                {timeLineDot}
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
                <Box className='flex-row' gap={1} alignItems={'center'}>
                    <Typography variant='body2'>
                        {type}
                    </Typography>

                    {
                        !isEncounter &&
                        <>
                            <IconButton 
                                size='small'
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setArchiveDoc(entry.data)
                                    setOpen('document')
                                }}>
                                < DeleteOutlineOutlinedIcon style={{fontSize:18}}/>
                            </IconButton>
                            <DeleteDocDialog open={open === 'document'} docToArchive={docToArchive} handleClose={handleClose}/>
                        </>
                    }
                    {
                        (isEncounter && isDeveloper) ?
                        <>
                            <IconButton 
                                size='small'
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setEncounterToDelete(entry)
                                    setOpen('encounter')
                                }}>
                                < DeleteOutlineOutlinedIcon style={{fontSize:18}}/>
                            </IconButton>
                            <DeleteEncounterDialog open={open === 'encounter'} handleClose={handleClose} encounterToDelete={encounterToDelete}/>
                        </>
                        :
                        null
                    }
                </Box>
                <Box className='flex-col'>
                    {isEncounter ? (
                        data.qa_rejected ? (
                            <QABody data={data} theme={theme} />
                        ) : (
                            <EncounterBody type={type} data={data} theme={theme} />
                        )
                    ) : (
                            <LabBody data={data} theme={theme} />                            
                    )}
                </Box>
            </TimelineContent>
        </TimelineItem>
    );
}
