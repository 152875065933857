import { DataGridPro } from "@mui/x-data-grid-pro";
import { useGetPatientsListQuery } from "../../../patientAPISlice";
import { Box, Tooltip, Typography } from "@mui/material";
import { useCallback,  useEffect,  useState} from "react";
import { stringToTitleCase } from "../../../../../utils/stringUtils/titleCase"; 
import dayjs from "dayjs";
import { linkedRowProps, stickyHeaderProps } from "../../../../globalComponents/DataGrid/gridStyleProps/gridStyleProps";
import DefaultNoRowsOverlay from "../../../../globalComponents/DataGrid/subComponents/noRowsOverlays/DefaultNoRowsOverlay"
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectPatientListFilters, setPatientListFilters } from "../../../../admin/adminSlice"
import usePaginatedRowCount from "../../../../globalComponents/DataGrid/hooks/commonUseEffects/usePaginatedRowCount";
import { PatientListToolbar } from "./PatientListToolbar";
import { MemoCell } from "../../../../globalComponents/DataGrid/performance/MemoGridItems";
import { Link } from "react-router-dom"
import SitesFilterChip from "../../../../globalComponents/Chips/DropDownChip/SItesFilterChip/SitesFilterChip";
import ReduxStoreSearchBar from "../../../../globalComponents/SearchBars/ReduxStoreSearchBar/ReduxStoreSearchBar";
import { ChevronLeft } from "@mui/icons-material";
import { TooltipInfoIcon } from "../../../../../assets/icons/MaterialDesignIcons";

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width:88,
    },
    {   
        field: 'first_name',
        headerName: 'Name', 
        flex:1,
        valueGetter:(value,row) => { //need to do this to allow for proper backend sorting
            return stringToTitleCase(row.name) || '-'
        }
    },
    {
        field: 'date_of_birth',
        headerName: 'Date of Birth',
        flex:1,
        valueGetter:(value,row) => {
            if (!value) return '-'

            return dayjs(value).format('MMM D, YYYY') 
        }
    },
    {
        field: 'site_name',
        headerName: 'Site',
        flex:1,
        valueGetter: (value) => value || '-'
    },
    {
        field: 'primary_insurance',
        headerName: 'Primary ins.',
        flex:1,
        valueGetter: (value) => value || '-'
    },
    {
        field: 'primary_insurance_id',
        headerName: 'Primary ins. id',
        flex:1,
        valueGetter: (value) => value || '-'
    },
    {
        field:'last_review',
        headerName: 'Last review',
        minWidth:150,
        flex:1,
        renderCell: ({row}) => {
            const {last_review} = row || {}
            const {type} = last_review || {}
            const isInfec = type?.includes('Infectious')

            return(
                <Box className='flex-row' 
                    sx={{ 
                        height:'100%', 
                        width:'100%', 
                        alignItems:'center'
                        }}>
                        {
                            last_review?.date ? 
                            <Link className='flex-row' style={{ textDecoration: 'none', height:'100%', width:'100%', alignItems:'center' }} to={`/caseload/encounter-details/${isInfec? 'Infectious Disease' : 'Pathology'}/${last_review?.encounter_id}`} title={dayjs(last_review?.date).format("MMM D, YYYY")}>
                                <Typography variant='body2' color={'primary'} noWrap>{dayjs(last_review?.date).format("MMM D, YYYY")} - {isInfec ? 'Infectious':'Pathology'}</Typography>
                            </Link>
                            :
                            <Typography variant='body2'>-</Typography>
                        }
                </Box>
            )
        }
    }
]

const sxProps = {
    border:'none',
    "& .MuiDataGrid-virtualScroller": { overflow:  "hidden" },
    '& .MuiDataGrid-columnHeaderTitleContainerContent': { '& .MuiCheckbox-root' : { visibility:'hidden' } },
    ...linkedRowProps,
    ...stickyHeaderProps
}

export default function PatientListPanel({user}){

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { groups } = user || {}

    const params  = useSelector(selectPatientListFilters)
    const {data, isLoading, isFetching} = useGetPatientsListQuery({
                                pagination:params.pagination, 
                                ordering:params.ordering, 
                                sites:params.sites, 
                                search:params.search
                            })

    const rowCountState = usePaginatedRowCount(data?.count)
    const [selected, setSelected] = useState([])
    const [selectedRowData, setSelectedRowData] = useState(null)
    const isSelected = selected?.length > 0

    const setParams = useCallback((state) => { dispatch(setPatientListFilters(state)); }, [dispatch]);
    
    const handleSortChange = (sortModel) => {
        const {field, sort} = sortModel[0] || {}
        
        let order = field

        if(field === 'site_name'){
            order = 'site__site_name'
        }   
        if(sort === 'desc'){
            order = '-' + field
        }
        
        // resetPaginaton()
        setParams({ordering:order})
    }

    const resetPaginaton = () => setParams({pagination: { page:0, pageSize:10 }})

    const handlePaginationChange = (pageModel) => setParams({pagination:pageModel}) 

    const handleFilterChange = useCallback((filterName) => (value) => {
        setParams({[filterName]: value})
        // resetPaginaton()
    }, [setParams]);

    const handleRowSelection = useCallback((ids) => {               
        let id = ids[ids.length-1]
        if(id) {
            setSelected([id])
            const selectedRow = data?.results.find((row) => row.id === id)
            setSelectedRowData(selectedRow)
        }
        else {
            setSelected([])
            setSelectedRowData(null)
        }
    },[setSelected, data])


    const handleCellClick = ({field, row}) =>{
        
        if (field.includes('__') || field === 'last_review') return

        else{
            navigate('/patients/profile/' + row.id)
        }
    }

    useEffect(() => {

        resetPaginaton()

    }, [params?.ordering, params?.sites, params?.search])

    return(
    <>
        <Box className='flex-row-between'>
            <SitesFilterChip
                filterState={params.sites}
                setFilterState={handleFilterChange('sites')}/>
            <ReduxStoreSearchBar 
                selectorPath={'admin.patientListFilters.search'}
                setter={(val) => setPatientListFilters({search:val}) }
                placeholder={'Search patients'}
                sx={(theme) => ({
                    backgroundColor:theme.palette.primary.shades8,
                })}
                />
        </Box>
        <Box sx={(theme) => ({ ...theme.standardBox})} >
            <DataGridPro
                columns={columns}
                rows={data?.results || []}
                columnHeaderHeight={isSelected? 0 : 56}
                paginationMode="server"
                sortingMode="server"
                pagination
                autoHeight
                disableColumnMenu
                checkboxSelection={groups?.Assignment || groups?.['Internal Staff']}
                rowCount={rowCountState}
                loading={isLoading || isFetching}
                pageSizeOptions={[5, 10, 15, 20, 50]}
                onSortModelChange={handleSortChange}
                paginationModel={params?.pagination}
                onPaginationModelChange={handlePaginationChange}
                rowSelectionModel={selected}
                onRowSelectionModelChange={handleRowSelection}
                onCellClick={handleCellClick}
                slots={{
                    noRowsOverlay: DefaultNoRowsOverlay,
                    toolbar: isSelected? PatientListToolbar : null,
                    cell: MemoCell
                  }}
                slotProps={{
                    noRowsOverlay: { message: 'No patients found' },
                    toolbar: { selected, setSelected, groups, selectedRowData }
                }}
                sx={sxProps}
            />
        </Box>
    </>
    )
}