import { Box, Button } from "@mui/material";
import { SmallHeaderBox, TypographySubtitle } from "../../../globalComponents/SubHeader/components";
import BackButton from "../../../globalComponents/SubHeader/Backbutton";
import DocDownloadBtn from "../DocDownloadBtn/DocDownloadBtn";
import ClinicianPatientHeader from "../../../patients/components/ClinicianPatientHeader/ClinicianPatientHeader";
import { useTheme } from "@emotion/react";
import { stringToTitleCase } from "../../../../utils/stringUtils/titleCase";
import { CaseHeaderLoader, SubHeaderLoader } from "../../../globalComponents/Loaders";
import { usePrefetchEndpoint } from "../../../../utils/apiUtils/usePrefetchEndpoint";



export default function CaseDetailsNav({
    encounter, 
    isInfecDisCase,
    billableEvent,
    encounterId,
    qaObj,
    openFaxModal,
    isLoading,
    compensationObjs
}){

    const theme = useTheme()
    const prefetch = usePrefetchEndpoint()

    if(isLoading){
        return(
            <>
                <SubHeaderLoader/>
                <CaseHeaderLoader/>
            </>
        )
    }


    return(
    <>
        <Box sx={{...theme.stickyHeader}}>
            <SmallHeaderBox>
                <Box className='flex-row-center'>
                    <BackButton/>
                    <TypographySubtitle>{stringToTitleCase(encounter?.patient?.name)}</TypographySubtitle>
                </Box>
                <Box className='flex-row' gap={1}>
                    <Button  
                        variant='outlined'
                        onMouseEnter={() => prefetch('getFaxRecords', encounter?.patient?.id)} 
                        onClick={openFaxModal}
                        sx={{
                            borderRadius:'18px', 
                            p: '5px 15px', 
                            height: 32
                        }} > 
                        Fax document to provider 
                    </Button>
                    <DocDownloadBtn 
                        encounterId={encounterId} 
                        isInfecDisCase={isInfecDisCase}/>
                </Box>
            </SmallHeaderBox>
        </Box>
        <ClinicianPatientHeader 
            patient={encounter?.patient} 
            billableEvent={billableEvent}
            compensationObjs={compensationObjs}
            encounter={encounter}
            qaObj={qaObj}/>
    </>
    )

}