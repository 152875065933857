import { useTheme } from "@emotion/react"
import { Box, Collapse, Divider } from "@mui/material"
import { useState } from "react"
import ClinicianFrame from "./HeaderFrames/ClinicianFrame";
import PatientFrame from "./HeaderFrames/PatientFrame";



export default function ClinicianPatientHeader({patient, billableEvent, encounter, qaObj, compensationObjs}){

    const theme = useTheme()
    const [isExpanded, setIsExpanded] = useState(false)
    const pendingQA = Boolean(qaObj?.review_state_string === 'pending' || qaObj?.review_state_string === 'in-progress')


    return(
        <>
        <Box height={196} width={'100%'}></Box>
        <Box display={'flex'} mt={'64px'} 
                onMouseEnter={() => setIsExpanded(true)}
                onMouseLeave={() => setIsExpanded(false)}
                sx={{
                    ...theme.stickyHeader,
                    zIndex:1099,
                    background:theme.palette.background.paper
                }}> 
            <Collapse
                in={isExpanded} 
                collapsedSize={128}  
                sx={{ 
                    width:'100%',
                    borderBottom:`1px solid ${theme.palette.divider}`
                }}>
            <Box className='flex-row' height={192} p={3} pt={2}  gap={3}>
                <ClinicianFrame 
                    billableEvent={billableEvent}
                    compensationObjs={compensationObjs}
                    encounter={encounter}
                    pendingQA={pendingQA}/>
                <Divider orientation='vertical' flexItem sx={{ height: isExpanded? 'auto' : 100, }}/>
                <PatientFrame patient={patient}/>
            </Box>

            </Collapse>
        </Box>
        </>
    )
}