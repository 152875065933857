import { Box } from "@mui/material";
import dayjs from "dayjs";
import { show400Error } from "../../../../../../../../app/errors/genericErrors";
import { HaloDatePicker, HaloObjAutocomplete, HaloTextField } from "../../../../../../../globalComponents/ReactHookFormFields";




export default function SampleForm({
    sample_type_options, 
    readOnly,
    manageSample,
    control,
    handleSubmit,
    isDirty,
    reset
}) {
    

    const handleSave = (values) =>{
        if(isDirty){

            manageSample({
                method: 'PUT',
                body: {
                    ...values,
                    sample_date: values?.sample_date ? values?.sample_date?.format('YYYY-MM-DD') : '',
                    sample_type: values.sample_type.id, //sending id only
                }
            })
            .unwrap()
            .then(res => reset( {...values}, {keepDirtyValues:true, keepDirty:false, }))
            .catch(err => show400Error(err))
        }
    }

    
    return (
        <Box 
            className="flex-col" 
            gap={'18px'}
            onBlur={handleSubmit(handleSave)}
            >
            <Box className='flex-row' gap={2}>
                <HaloTextField 
                    name='sample_id'
                    label='Sample ID'
                    required
                    hideHelperText
                    disabled={readOnly}
                    control={control}
                />
                <HaloObjAutocomplete 
                    name='sample_type'
                    label='Type'
                    required
                    hideHelperText
                    disabled={readOnly}
                    options={sample_type_options}
                    optionKey='name'
                    useIdAsKey
                    control={control}
                />
            </Box>
            <Box className='flex-row' gap={2}>
                <HaloDatePicker 
                    name='sample_date'
                    label='Date'
                    required
                    disabled={readOnly}
                    minDate={dayjs().subtract(2, 'week')}
                    maxDate={dayjs().add(2, 'week')}
                    control={control}                    
                />
                <HaloTextField 
                    name='sample_name'
                    label='Sample name'
                    required
                    hideHelperText
                    disabled={readOnly}
                    control={control}
                />
            </Box>
        </Box>
    )
}
