import { Badge, styled } from '@mui/material';


const UserActivityIndicator = styled(Badge)(({ theme, activityStatus}) => ({
    '& .MuiBadge-badge': {
        backgroundColor: 
            activityStatus === 'active'
                ? 'green'
                : activityStatus === 'idle'
                ? 'orange'
                : 'grey',
        color:
            activityStatus === 'active'
                ? 'green[300]'
                : activityStatus === 'idle'
                ? 'orange'
                : 'grey',
        width: 10,
        height: 10,
        borderRadius: '50%',
        border: `0.5px solid ${theme.palette.background.paper}`,
        boxShadow: `0 0 0 0.5px ${theme.palette.background.paper}`,
    },
}));

export default UserActivityIndicator;