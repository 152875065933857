import { Typography } from "@mui/material";




export const LabBody = ({ data, theme }) => (
    <Typography sx={{ ...theme.typography.caption, color: theme.palette.text.disabled }}>
        Summary: {data?.summary || '-'}
    </Typography>
);
