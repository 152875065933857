import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { linkedRowProps }from '../../../../../globalComponents/DataGrid/gridStyleProps/gridStyleProps'
import { stringToTitleCase } from "../../../../../../utils/stringUtils/titleCase";
import DefaultNoRowsOverlay from "../../../../../globalComponents/DataGrid/subComponents/noRowsOverlays/DefaultNoRowsOverlay";
import { AssignmentStatusChipCell } from "../../../../../globalComponents/DataGrid/subComponents/customCells/AssignmentStatusChipCell";


const assignmentColumns = [
    {
        field: 'case_type',
        headerName: 'Case type',
        flex:1,
        sortable:false,
    },
    {
        field:'asignee',
        headerName:'Clinician',
        flex:1,
    },
    {
        field: 'status',
        headerName: 'Status',
        flex:1,
        sortable:false,
        renderCell: (params) =>  <AssignmentStatusChipCell {...params} />
    },
    {
        field: 'patient',
        headerName: 'Patient',
        flex:1,
        sortable:false,
        valueFormatter: (value) => stringToTitleCase(value)
    },
]

export default function SummaryContainer({assignments}){

    const navigate = useNavigate()

    return(
        <Box className='flex-col' gap={2} >
            <Typography variant="subtitle2" sx={{ fontWeight:600}}>
                To do
            </Typography>
            <Box sx={(theme) => ({...theme.standardBox, height:247})} >
                <DataGridPro
                    sx={{
                        ...linkedRowProps,
                    }}
                    columns={assignmentColumns}
                    rows={assignments || []}
                    disableColumnFilter
                    disableColumnMenu
                    hideFooter
                    slots={{
                        noRowsOverlay: DefaultNoRowsOverlay
                    }}
                    slotProps={{
                        noRowsOverlay:{
                            message:"No current case"
                        }
                    }}
                    onRowClick={(params) =>{
                        if(params.row.status==='completed' || params.row.status === 'pending_results'){ 
                            navigate(`/caseload/encounter-details/${params.row.case_type}/${params.row.case_object_id}`) //needs to use the pathology or infectious disease id 
                        }
                        else{
                            navigate('/caseload/case-review/'  + params.row.case_type + '/' + params.row.case)
                        }
                    }}/>
            </Box>
        </Box>
    )
}
