import { useState } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import {
    Avatar,
    Chip,
    Box,
    Typography,
    Button
} from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import UserMenu from "./UserMenu";
import { stringToTitleCase } from "../../../../../utils/stringUtils/titleCase";
import { selectCurrentUser } from "../../../../../features/auth/authSlice";
import UserAvatar from "../../../../../features/globalComponents/Avatar/UserAvatar";





export default function UserSection({ open, handleDarkMode }) {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const user = useSelector(selectCurrentUser);
    const isMenuOpen = Boolean(anchorEl);

    const handleMenuClick = event => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    

    return (
        <>
        <Button
            onClick={handleMenuClick}
            fullWidth
            sx={{
                mt:'auto',
                justifyContent:open ?'flex-start' : 'center',
                height:64,
                minWidth:48,
                width:open? '100%': 48,
                p: open?'12px' : 0,
                '.MuiButton-startIcon': {
                    m:0
                }
            }}
            color="inherit"
            startIcon={
                <UserAvatar 
                    firstName={user?.firstName} 
                    imgUrl={user?.googlePicture}    
                    sx={(theme) => ({ background: theme.palette.success.main, height:32, width:32, fontWeight:400 })}
                    />
            }>
            {
                open &&
                <Box className='flex-row-between-center' ml={2} gap={3} title={user.name ? stringToTitleCase(user.name) : ''}>
                    <Box className='flex-col' gap='2px' >
                        <Typography variant="body1"  noWrap maxWidth={100}>
                            {user.name ? stringToTitleCase(user.name) : ''}
                        </Typography>
                        <Chip 
                            size="small" 
                            color="success" 
                            label= {user.frontEndUserType ?? user.email }
                            sx={{ color: theme.palette.primary.contrast, fontSize: 12, width: 'fit-content', maxWidth: 100 }} 
                        />
                    </Box>
                    <KeyboardArrowUpIcon />
                </Box>
            }
        </Button>
        <UserMenu 
                isMenuOpen={isMenuOpen} 
                anchorEl={anchorEl} 
                open={open} 
                handleClose={handleMenuClose} 
                handleDarkMode={handleDarkMode}
            />
        </>
    );
}
