import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ActionButton } from '../../../../../../../globalComponents/Buttons/ActionButton/ActionButton';
import { useDispatch } from 'react-redux';
import { useDeleteCaseMutation, useGetDeleteCaseRelatedObjectsQuery } from '../../../../../../../rosterManagement/rosterAdminAPISlice';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DataObjectIcon from '@mui/icons-material/DataObject';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { Avatar } from '@mui/material';


export default function DeleteEncounterDialog({open, encounterToDelete, handleClose}){

    const dispatch = useDispatch()
    const caseId = encounterToDelete?.data?.case_id
    const { data:relatedObjs, isLoading, isFetching } = useGetDeleteCaseRelatedObjectsQuery(caseId  , {skip:!caseId})
    const [ deleteCase ] = useDeleteCaseMutation()
    const type = encounterToDelete?.type
    const date = dayjs(type === 'Infectious Disease Encounter' ? encounterToDelete?.data?.submitted_at : encounterToDelete?.data?.date_submitted).format('MMMM D, YYYY')


    const confirmDelete = () => {
        deleteCase(caseId)
        .then((res) => {
            enqueueSnackbar(res?.data, {variant:'success', style: { whiteSpace: 'pre-line' }},)
            handleClose()
        })

    }


    return(
        <Dialog open={open} onClick={(e) => e.stopPropagation()}>
            <DialogTitle id="Delete encounter">
                Delete encounter
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="Delete encounter">
                    Delete {type} ({date}) and related objects?
                </DialogContentText>
                <List dense disablePadding sx={{mt:2}}>
                    {
                    relatedObjs?.map((objType) => (
                        objType &&
                        <ListItem key={objType}>
                            <ListItemAvatar>
                                <Avatar sx={(theme) => ({bgcolor: theme.palette.primary.main})}>
                                    <DataObjectIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={objType} />
                        </ListItem>
                        )
                    )
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <ActionButton
                    color='inherit'
                    onClick={(e)=> {
                        handleClose()
                    }}>
                    Cancel
                </ActionButton>
                <ActionButton
                    onClick={confirmDelete}
                    loading={isLoading}
                    variant='contained'>
                    Delete
                </ActionButton>
            </DialogActions>
        </Dialog>
    )
}