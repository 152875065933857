import {  useEffect, useState } from "react";
import HaloModal from "../../../globalComponents/Modals/HaloModal";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { Upload } from "./Steps/Upload/Upload";
import { useForm, useWatch } from "react-hook-form";
import { MapFields } from "./Steps/MapFields";
import { ScanForErrors } from "./Steps/ScanForErrors/ScanForErrors";
import { useGetCSVColumns } from "./Steps/useGetCSVColumns";
import Summary from "./Steps/Summary/Summary";
import Type from "./Steps/Type";
import { useGetTemplateTypesQuery } from "../../dataIntakeAPISlice";


const steps =[ 
    'Type',
    'Upload',
    'Map',
    'Data Cleaning',
    'Summary'
]


export default function PatientIntakeModal({open, handleClose, initSite=null, initType=null}){

    const defaultValues = { 
        file: [], 
        template: {}, 
        // type == Patient upload && template_name is set? then it's an EMR template that's being made...
        // ...can we make this more obvious?
        template_name:''  
    }

    const { data:templateTypes } = useGetTemplateTypesQuery()
    const [step , setStep ] = useState(0) 
    const [type, setType] = useState('Patient upload')
    const {reset, control, formState:{isValid}, trigger, setValue} = useForm({ defaultValues })

    const watchedTemplate = useWatch({control, name:'template'})
    const watchedTemplateName = useWatch({control, name:'template_name'})
    const watchedSite = useWatch({control, name:'site'})
    const watchedFile =  useWatch({control, name:'file'})

    const csvColumns = useGetCSVColumns({template:watchedTemplate, file:watchedFile})
    
    const goNextStep = () => setStep((prev) => prev + 1 )
    const goPrevStep = () => setStep((prev) => prev - 1 )

    const resetState = () => {
        reset(defaultValues)
        setStep(0)
    }

    useEffect(() => {

        if(!open){
            resetState()
        }

    }, [open])


    return(
        <HaloModal
            open={open}
            handleClose={handleClose}
            title={'Upload data'}
            modalMaxWidth={1200}>
            <Box p={2}>
                <Stepper activeStep={step} alternativeLabel>
                    {
                        steps?.map((step) => (
                            <Step key={step}>
                                <StepLabel >{step}</StepLabel>
                            </Step>
                        ))
                    }
                </Stepper>
            </Box>
                {   
                    step === 0?
                        <Type 
                            goNextStep={goNextStep}
                            type={type}
                            setType={setType}
                        />
                    :
                    step === 1 ?
                        <Upload 
                            type={type}
                            control={control} 
                            trigger={trigger}
                            isValid={isValid}
                            goNextStep={goNextStep}
                            goPrevStep={goPrevStep}
                            site={watchedSite}
                            setValue={setValue}
                            templateTypes={templateTypes}
                            />
                    :
                    step === 2 ?
                        <MapFields 
                            csvColumns={csvColumns}
                            template={watchedTemplate}
                            goNextStep={goNextStep}
                            goPrevStep={goPrevStep}
                            site={watchedSite}
                            templateName={watchedTemplateName}
                            setValue={setValue}
                            type={type}
                            templateTypes={templateTypes}
                            />
                    :
                    step === 3?
                        <ScanForErrors
                            goNextStep={goNextStep}
                            goPrevStep={goPrevStep}
                            template={watchedTemplate}
                            file={watchedFile}
                            csvColumns={csvColumns}
                            setValue={setValue}
                        />
                    :
                    step === 4?
                        <Summary
                            goNextStep={goNextStep}
                            goPrevStep={goPrevStep}
                            site={watchedSite}
                            file={watchedFile}
                            template={watchedTemplate}
                            handleClose={handleClose}
                            type={type}
                            resetState={resetState}
                        />
                    :
                    null
                }
        </HaloModal>
    )
}