import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useNavigate } from "react-router-dom";
import DefaultNoRowsOverlay from '../../../../globalComponents/DataGrid/subComponents/noRowsOverlays/DefaultNoRowsOverlay'
import { useCallback, } from "react";
import { useGetEncounterHistoryQuery } from "../../../rosterAPISlice";
import dayjs from "dayjs";
import HistoryFilters from "./HistoryFilters";
import { useDispatch, useSelector } from "react-redux";
import {  resetEncounterHistoryPagination, selectEncounterHistoryFilters, setEncounterHistoryFilters } from "../../../rosterSlice";
import { linkedRowProps } from "../../../../globalComponents/DataGrid/gridStyleProps/gridStyleProps";
import usePaginatedRowCount from "../../../../globalComponents/DataGrid/hooks/commonUseEffects/usePaginatedRowCount";
import MDApprovedStatusChip from "../../../utils/StatusChips/MDApprovedStatusChip";
import  LinkedPatientCell  from "../../../../globalComponents/DataGrid/subComponents/customCells/LinkedPatientCell";

const columns =[
  {
      field: 'case_type',
      headerName: 'Case type',
      flex:1,
      renderCell:(params) =>{
         return <>{params?.formattedValue?.split(' ')[0]}</>
      },
      sortable:false
  },
  {
      field: 'patient',
      headerName: 'Patient',
      flex:1,
      renderCell:(params) => (
          <LinkedPatientCell
            patientName={params?.row?.patient?.name}
            patientId={params?.row?.patient?.id}
          />
        )
  },
  {
      field: 'site_name',
      headerName: 'Site',
      flex:1,
  },
  {
      field: 'date_of_birth',
      headerName: 'DOB',
      flex:1,
      valueGetter: (value, row) =>{
          return dayjs(row.patient.date_of_birth).format('LL')
      },
      sortable:false
  },
  {
    field:'date_submitted',
    headerName: 'Date submitted',
    flex:1,
    valueFormatter: (value) => {
      if(!value) return ''
      return dayjs(value).format('LL')
  }
  },
  {
    field:'md_review',
    headerName: 'MD approved',
    flex:0.75,
    sortable:true,
    minWidth:150,
    renderCell: ({row}) => {

      return(
        <MDApprovedStatusChip mdApproved={row?.md_review_obj?.is_approved} qaObj={row?.qa_feedback} mdReviewObj={row?.md_review_obj}/>
      )
    }
  },
  {
    field:'compensation_points',
    headerName: 'Compensation pts.',
    flex:0.75,
    renderCell:({row}) => {
      const {qa_feedback} = row || {}
      const {review_state_string:qa_status} = qa_feedback || {}
      let pending = qa_status === 'pending' || qa_status === 'in-progress'

      return(
        <Box display='flex' alignItems='center' height='100%'>
          <Typography variant='body2' noWrap sx={(theme) => ({color:pending? theme.palette.warning.main :''})}>{pending? 'QA pending' : row?.compensation_points}</Typography>
        </Box>
      )


    },
    sortable:false
  },
]



export default function EncounterHistoryTabPanel() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  
  const queryOptions = useSelector(selectEncounterHistoryFilters)

  const { data, isLoading, isFetching } = useGetEncounterHistoryQuery({ ...queryOptions, page: queryOptions.pagination.page + 1, search: queryOptions.searched });
  const rowCountState = usePaginatedRowCount(data?.count)
  
  const resetPagination = () => dispatch(resetEncounterHistoryPagination)
  const setParams = useCallback((state) => { 
      dispatch(setEncounterHistoryFilters(state)); 
    }, [dispatch]);
  
  const handleSortModelChange = (sortModel) => {

    const {field, sort} = sortModel[0] || {}

    let order = field

    if(field === 'patient'){
        order = 'patient__first_name'
    }else if (field === 'site_name'){
        order = 'patient__site__site_name'
    }   

    if(sort === 'desc'){
        order = '-' + order
    }
    
    resetPagination()
    setParams({ordering:order})

  };

  const handlePaginationChange = (pageModel) => setParams({pagination:pageModel}) 


  const handleCellClick = ({field, row}) => {
    if (field.includes('__') || field === 'patient') return

    else{
      navigate(`/caseload/encounter-details/${row.case_type}/${row.id}`)
    }
  }

  return (
    <>
      <Box className="flex-row-between">
        <HistoryFilters //chips and search bar
            queryOptions={queryOptions}
            setParams={setParams}
        />
      </Box>
      <Box sx={{ ...theme.standardBox}}>
        <DataGridPro
          pagination
          autoHeight
          columns={columns}
          rows={data?.results || []}
          rowCount={rowCountState}
          onCellClick={handleCellClick}
          loading={isLoading || isFetching}
          sortingMode="server"
          paginationMode="server"
          pageSizeOptions={[5, 10, 15, 20, 50]}
          initialState={{
            sorting: {
              sortModel: [{ field: 'date_submitted', sort: 'desc' }],
            },
          }}
          onSortModelChange={handleSortModelChange}
          paginationModel={queryOptions.pagination}
          onPaginationModelChange={handlePaginationChange}
          disableColumnMenu
          slots={{
            noRowsOverlay: DefaultNoRowsOverlay,
          }}
          slotProps={{
            noRowsOverlay: { message: 'No cases' }
          }}
          sx={{
            border:'1px solid rgba(255,255,255,0)',
            ...linkedRowProps
          }}
        />
      </Box>
    </>
  );
}
